<template>
  <div>
     
     <b-tabs content-class="pt-2" >
             <b-tab  :title="`WEBINARS `" active>
                   <b-card-text>
                     <Webinars :showInBackOffice="showInBackOffice" :webinars="webinars"></Webinars>
                   </b-card-text>
             </b-tab>
             <b-tab  :title="`ATELIERS `" >
                   <b-card-text>
                     <Ateliers :showInBackOffice="showInBackOffice"  :ateliers="ateliers"></Ateliers>
                   </b-card-text>
             </b-tab>
             <b-tab  :title="`POP-UP WEBINAR `" >
                   <b-card-text>
                     <PopupWebinar  :popups="popups"></PopupWebinar>
                   </b-card-text>
             </b-tab>
     </b-tabs> 
    
 </div>
</template>

<script>
import { BTab, BTabs,BCardText  } from 'bootstrap-vue'
import Webinars from './Lists/Webinars'
import Ateliers from './Lists/Ateliers'
import PopupWebinar from './Lists/PopupWebinar'
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";
export default {
  components: {
    Webinars,
    Ateliers,
    PopupWebinar,
    BTab,
    BTabs,
    BCardText, 
    ToastificationContentVue
  },
  props: {
    showInBackOffice: {
      type: Boolean,
      default: false,
    },
  },
  data(){
    return{
      webinars:[],
      ateliers:[],
      popups:[]
    }
  }
 ,
 created() {
      this.fetchAllData();
  },
 methods:{
  messageToast(text, title, variant) {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title,
            icon: "BellIcon",
            text,
            variant,
          },
        });
      },
  async fetchAllData(){
       await this.$http
          .get(`/webinars`)
          .then((res) => {
            if (res.data.success) {
              this.webinars = res.data.data.webinars;
              this.popups = res.data.data.popups;
              this.ateliers = res.data.data.ateliers;
            } else {
              this.messageToast(
                "Nous n'avons pas pu trouver la ressource que vous avez demandée.",
                "Erreur",
                "warning"
              );
            }
          })
          .catch((err) => {
            this.messageToast(err.response.data.errors[0], "Erreur", "error");
            console.error(err);
          });
  }
 }
};
</script>
